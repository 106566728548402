<template>
	<section class="px-5" v-loading="loading">
		<div class="row mx-0">
			<div class="col-8 col-sm-8 col-md-8 col-lg-7 col-xl-7 px-0">
				<div class="row mx-0 align-items-center">
					<div class="col-auto pl-0 text-black f-600 f-20">
						Información general
					</div>
					<div class="col-auto px-1 ml-auto">
						<div v-if="conjunto.estado==0" class="bg-pink br-20 px-3 text-general">
							Inactivo 
						</div>
						<div v-else class="bg-general br-20 px-3 text-white">
							Activo 
						</div>
					</div>
					<div class="col-auto pr-0">
						<el-popover
						placement="bottom-start"
						trigger="hover">
							<div v-show="conjunto.estado" class="row mx-0 align-items-center cr-pointer text-black px-2 br-4 item" style="height:25px;" @click="modalInactivarConjunto">
								Inactivar
							</div>
							<div v-show="!conjunto.estado" class="row mx-0 align-items-center cr-pointer text-black px-2 br-4 item" style="height:25px;" @click="modalActivarConjunto">
								Activar
							</div>
							<div class="row mx-0 align-items-center cr-pointer text-black px-2 br-4 item" style="height:25px;" @click="modalEliminarConjunto">
								Eliminar
							</div>
							<div slot="reference" class="br-4 border btn-action">
								<i class="icon-opciones f-16 text-black" />
							</div>
						</el-popover>
					</div>
				</div>
				<div class="row mx-0 py-3" />
				<ValidationObserver ref="form">
					<div class="row mx-0">
						<div class="col-auto px-0">
							<p class="text-general f-12 text-center">Imagen</p>
							<el-upload
								class="avatar-uploader"
								action="#"
								:auto-upload="false"
								:show-file-list="false"
								:on-change="handleChange"
								>
							<img v-if="conjunto.logo" :src="conjunto.logo" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</div>
						<div class="col-8 ml-4 pr-0">
							<p class="text-general f-12 pl-3"> Nombre </p>
							<ValidationProvider v-slot="{errors}" rules="required|max:50" name="nombre">
								<el-input v-model="conjunto.nombre" class="w-100" show-word-limit maxlength="50" size="small" />
								<span class="text-danger f-10">{{ errors[0] }}</span>
							</ValidationProvider>
							<p class="text-general f-12 pl-3 mt-3"> Tipo de conjunto </p>
							<ValidationProvider v-slot="{errors}" rules="required" name="tipo">
								<el-select v-model="conjunto.tipo" placeholder="Select" size="small" class="w-100">
									<el-option
									v-for="tipo in tipos"
									:key="tipo.value"
									:label="tipo.label"
									:value="tipo.value">
									</el-option>
								</el-select>
							<span class="text-danger f-10">{{ errors[0] }}</span>
							</ValidationProvider>
							<div class="row mx-0 mt-3">
								<div class="col pl-0">
									<p class="text-general f-12 pl-3"> Ciudad </p>
									<ValidationProvider v-slot="{errors}" rules="required" name="tipo">
										<el-select v-model="conjunto.id_ciudad" placeholder="Select" filterable remote :remote-method="remoteMethod" size="small" class="w-100">
											<el-option
											v-for="ciudad in ciudades"
											:key="ciudad.id"
											:label="ciudad.ciudad"
											:value="ciudad.id">
											</el-option>
										</el-select>
									<span class="text-danger f-10">{{ errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
							<p class="text-general pl-3 f-12 mt-3">Dirección</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:100" name="direccion">
                                <el-input v-model="conjunto.direccion" placeholder="Dirección"  show-word-limit maxlength="100" class="w-100" size="small" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
							<div class="row mx-0 mt-3">
                                <div class="col pl-0">
                                    <p class="text-general pl-3 f-12 mt-2">Latitud</p>
                                    <ValidationProvider v-slot="{ errors }" :rules="{regex: /^\d*\.?\d*$/}" name="latitud">
                                        <el-input v-model="conjunto.latitud" placeholder="latitud" class="w-100" size="small" />
                                        <span class="text-danger f-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col pr-0">
                                    <p class="text-general pl-3 f-12 mt-2">Longitud</p>
                                    <ValidationProvider v-slot="{ errors }" name="longitud">
                                        <el-input v-model="conjunto.longitud" placeholder="longitud" class="w-100" size="small" />
                                        <span class="text-danger f-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
							<div class="row mx-0 mt-3">
								<div class="bg-general cr-pointer text-white d-middle-center px-3 f-14 br-4" style="height:32px;" @click="guardar">
									Guardar
								</div>
							</div>
							<div class="row mx-0 py-3" />
							<div class="row mx-0 f-14">
								<div class="col-auto px-0 text-86 f-600">
									Última actualización:
								</div>
								<div class="col-auto text-86 ">
									{{ fechaFormat(conjunto.updated_at) }}
								</div>
							</div>
						</div>
					</div>
				</ValidationObserver>
			</div>
		</div>
		<!-- Partials -->
		<modal ref="modalInactivarConjunto" titulo="Inactivar conjunto" no-aceptar adicional="Inactivar" @adicional="inactivarConjunto">
			<div class="row mx-0 my-4">
				<div class="col-12 text-general text-center f-500">
					¿Desea inactivar este conjunto?
				</div>
				<div class="col-12 text-general text-center mt-2">
					Ningún usuario (Administrador o Residente) podrá acceder.
				</div>
			</div>
		</modal>
		<modal ref="modalActivarConjunto" titulo="Activar conjunto" no-aceptar adicional="Activar" @adicional="activarConjunto">
			<div class="row mx-0 my-4">
				<div class="col-12 text-general text-center f-500">
					¿Desea activar este conjunto?
				</div>
			</div>
		</modal>
		<modal-eliminar
		ref="modalEliminarConjunto"
		titulo="Eliminar conjunto"
		:mensaje="estado_conjunto ==1  ? '¿Desea eliminar este conjunto?' : ''"
		:sub-mensaje="estado_conjunto == 2  ? 'No puedes eliminar este conjunto porque hay usuarios (Administradores y Residentes) siendo parte de este.' : ''"
		@eliminar="eliminarConjunto"
		/>
	</section>
</template>

<script>
import moment from 'moment'
require('moment/locale/es');
import Conjuntos from '~/services/conjuntos'
import { mapGetters } from 'vuex'
export default {
	data(){
		return {
      	loading:false,
			value: '',
			estado_conjunto: 1,
      		tipos: [{
							value: 1,
							label: 'Apartamentos'
					}, {
							value: 2,
							label: 'Casas'
					}, {
							value: 3,
							label: 'Aptos y Casas'
					}, {
							value: 4,
							label: 'Otros'
					}],

			dialogImageUrl: '',
			dialogVisible: false,
			logo: '',
			conjunto: {},
			search: '',
			ciudades: []
		}
	},
  	mounted(){
	  this.conjunto = _.clone(this.conjuntoReal)
	  this.getCiudad()
  	},
	computed:{
		...mapGetters({
		conjuntoReal: 'conjuntos/conjunto',
		}),
	},
	methods: {
    fechaFormat(fecha){
      return moment(fecha).format('DD MMMM YYYY')
    },
    handleChange(file, filelist) {
      const isIMG = (file.raw.type === 'image/png' || file.raw.type === 'image/jpeg');

      if (!isIMG) {
        this.$message.error('Solo se permiten imágenes')
      }

      this.logo = file.raw

      this.conjunto.logo = URL.createObjectURL(file.raw);

      return isIMG
    },
	modalInactivarConjunto(){
		this.$refs.modalInactivarConjunto.toggle();
	},
	modalActivarConjunto(){
		this.$refs.modalActivarConjunto.toggle();
	},
	modalEliminarConjunto(){
		this.$refs.modalEliminarConjunto.toggle();
	},
    setImagen(){
      console.log(this.$refs.imagenCropper.get_image())
    },
    setCiudad(ciudad){
      this.conjunto.id_ciudad = ciudad
    },
    async guardar(){
      try {

		let validate = await this.$refs.form.validate()
		if(!validate || !this.conjunto.id_ciudad) return this.notificacion('','Campos incompletos','warning')

        this.loading = true

        let formData = new FormData();
            formData.append("id_conjunto", this.conjunto.id)
            formData.append("logo", this.logo)
            formData.append("direccion", this.conjunto.direccion)
            formData.append("id_ciudad", this.conjunto.id_ciudad)
            formData.append("latitud", this.conjunto.latitud)
            formData.append("longitud", this.conjunto.longitud)
            formData.append("nombre", this.conjunto.nombre)
            formData.append("tipo", this.conjunto.tipo)
        
        const {data} = await Conjuntos.guardar(formData)
        if(data.success){
          this.$notify({
            title: 'Conjunto guardado ',
            message: data.mensaje,
            type: 'success'
          });			
        }
        this.loading = false
      } catch (e){
        console.log(e)
        this.$notify({
          title: 'Guardando Conjunto',
          message: "Error en el proceso de guardar",
          type: 'warning'
        });		
        this.loading = false
      }
    },
	async inactivarConjunto(){
		try {
			const { data } = await Conjuntos.inactivarConjunto(this.conjunto.id)
			
			this.conjunto.estado = 0
			this.notificacion('','Conjunto inactivado correctamente','success')

			this.$refs.modalInactivarConjunto.toggle()
		} catch (error) {
			this.error_catch(error)
		}
	},
	async activarConjunto(){
		try {
			const { data } = await Conjuntos.activarConjunto(this.conjunto.id)
			
			this.conjunto.estado = 1
			this.notificacion('','Conjunto activado correctamente','success')

			this.$refs.modalActivarConjunto.toggle()
		} catch (error) {
			this.error_catch(error)
		}
	},
	async eliminarConjunto(){
		try {
			const { data } = await Conjuntos.eliminarConjunto(this.conjunto.id)

			this.notificacion('','Conjunto eliminado correctamente','success')
			this.$refs.modalEliminarConjunto.toggle()

			this.$router.replace('/')
		} catch (error) {
			this.error_catch(error)
		}
	},
	remoteMethod(search){
      this.search = search
      setTimeout(() => {
        this.loading = false;
        this.getCiudad()
      }, 100);
    },
	async getCiudad(){
		try {
			let params = {
				buscar : this.search
			}

			const { data } = await Conjuntos.getCiudad(params)
			this.ciudades = data?.data

			const dato = {
				id : this.conjunto.id_ciudad,
				ciudad: this.conjunto.ciudad,
			}

			const exist = this.ciudades.filter((item) => item.id == this.conjunto.id_ciudad).length == 1	
			if (exist == false) this.ciudades.push(dato);
		} catch (error) {
			this.error_catch(error)
		}
	}
	}
}
</script>
<style lang="scss" scoped>
.item:hover{
	background-color:#EBECF0a8;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>